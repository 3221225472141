import './FrameworksTable.scss';

import React, { useState, useMemo } from 'react';
import { sortBy } from 'lodash';

import { NoMatches } from 'components/framework-filters';

import FrameworkTableRow from './FrameworkTableRow';

import { Framework, Term } from 'types';

import TableHeading from './TableHeading';

type sortableAttribute = 'name' | 'age' | 'setting' | 'learningProgression';

interface Props {
  frameworks: Framework[];
  terms: Term[];
  resetFilters(): void;
}

const FrameworksTable: React.FC<Props> = (props) => {
  const { resetFilters } = props;

  const [sort, setSort] = useState({
    attribute: 'name' as sortableAttribute,
    ascending: true
  });

  const setSortAttribute = (attribute: sortableAttribute) => {
    setSort({
      attribute,
      ascending: attribute === sort.attribute ? !sort.ascending : true
    });
  };

  const termsForFrameworkWithID = (frameworkID: number): Term[] => {
    return props.terms.filter((t) => t.frameworkID === frameworkID);
  };

  const frameworks = useMemo(() => {
    const sorted = sortBy(props.frameworks, (t) => t[sort.attribute]);
    return sort.ascending ? sorted : sorted.reverse();
  }, [props.frameworks, sort]);

  return (
    <div className="FrameworksTable" aria-live="polite">
      <div className="FrameworksTable-header no-print">
        <h4 className="FrameworksTable-header-name-col">
          <TableHeading
            name="Framework Name"
            attribute="name"
            sort={sort}
            setSortAttribute={setSortAttribute}
          />
        </h4>

        <h4 className="FrameworksTable-header-metaData-col">
          <TableHeading
            name="Age Range"
            attribute="age"
            sort={sort}
            setSortAttribute={setSortAttribute}
          />
        </h4>

        <h4 className="FrameworksTable-header-metaData-col">
          <TableHeading
            name="Setting"
            attribute="setting"
            sort={sort}
            setSortAttribute={setSortAttribute}
          />
        </h4>

        <h4 className="FrameworksTable-header-metaData-col">
          <TableHeading
            name="Learning Progression"
            attribute="learningProgression"
            sort={sort}
            setSortAttribute={setSortAttribute}
          />
        </h4>

        <div className="FrameworksTable-header-link-col" />
      </div>

      {frameworks.length === 0 && <NoMatches resetFilters={resetFilters} />}

      {frameworks.map((framework) => (
        <FrameworkTableRow
          key={framework.identifier}
          framework={framework}
          terms={termsForFrameworkWithID(framework.identifier)}
        />
      ))}
    </div>
  );
};

export default FrameworksTable;
