import './TableHeading.scss';

import React from 'react';

interface Props {
  name: string;
  attribute: string;
  sort: { attribute: string; ascending: boolean };
  setSortAttribute(attribute: string): void;
}

const TableHeading: React.FC<Props> = (props) => {
  const { name, attribute, sort, setSortAttribute } = props;

  return (
    <button
      onClick={() => setSortAttribute(attribute)}
      className="FrameworksTableHeading"
    >
      {name}
      {attribute === sort.attribute && (
        <span className="FrameworksTableHeading-icon">
          {sort.ascending ? '▲' : '▼'}
        </span>
      )}
    </button>
  );
};

export default TableHeading;
