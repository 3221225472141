import './FrameworkTableRow.scss';

import React from 'react';
import { Link } from 'gatsby';
import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';
import { Framework, Term } from 'types';

interface Props {
  framework: Framework;
  terms: Term[];
}

const FrameworkTableRow: React.FC<Props> = (props) => {
  const { framework, terms } = props;

  return (
    <Link
      className="FrameworkTableRow"
      to={`/frameworks/${framework.identifier}/`}
    >
      <div className="FrameworkTableRow-name">
        <div className="FrameworkTableRow-thumbnailGraph">
          {terms.length > 0 && (
            <ThumbnailFrameworkGraph
              framework={framework}
              terms={terms}
              width={34}
              height={44}
            />
          )}
        </div>

        <div>{framework.name}</div>
      </div>

      <div className="FrameworkTableRow-age">{framework.age}</div>
      <div className="FrameworkTableRow-setting">{framework.setting}</div>
      <div className="FrameworkTableRow-learning-progression">
        {framework.learningProgression}
      </div>
      <div className="FrameworkTableRow-link no-print">Learn More</div>
    </Link>
  );
};

export default FrameworkTableRow;
