import React from 'react';

import FrameworksTable from 'components/frameworks/FrameworksTable';

import { Framework, Term } from 'types';

import FrameworkFilters from 'components/framework-filters/FrameworkFilters';
import { useFrameworkFilters } from 'components/framework-filters/useFrameworkFilters';

interface Props {
  frameworks: Framework[];
  terms: Term[];
}

const Frameworks: React.FC<Props> = (props) => {
  const { frameworks, terms } = props;

  const filters = useFrameworkFilters(frameworks);

  return (
    <>
      <FrameworkFilters filters={filters} />

      <FrameworksTable
        frameworks={filters.frameworks}
        terms={terms}
        resetFilters={filters.reset}
      />
    </>
  );
};

export default Frameworks;
