import './frameworks.scss';

import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'components/layout/Layout';
import Frameworks from 'components/frameworks/Frameworks';
import { CompareFrameworksLink } from 'components/VisualToolLink';

import { Framework, Term } from 'types';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
  };
}

const FrameworksPage: React.FC<Props> = (props) => {
  const frameworks = props.data.allFrameworks.frameworks.filter(
    (f) => f.identifier === f.group
  );
  const { terms } = props.data.allTerms;

  return (
    <Layout>
      <div className="FrameworksPage-header">
        <div className="FrameworksPage-header-copy">
          <h1>Look Inside Frameworks</h1>
          <p className="emphasis">
            Use the Framework Profiles to learn more about a specific framework
            and compare it to others in the field. Select a framework below to
            get started.
          </p>

          <p>
            <Link to="/faq/#included-frameworks">
              How do we decide which frameworks to include?
            </Link>
          </p>
          <p>
            <Link to="/faq/#adding-frameworks">
              Are you adding new frameworks?
            </Link>
          </p>
        </div>

        <div className="no-print">
          <CompareFrameworksLink displayStyle="thumbnail" />
        </div>
      </div>

      <Frameworks frameworks={frameworks} terms={terms} />
    </Layout>
  );
};

export default FrameworksPage;

export const query = graphql`
  query FrameworksQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        group
        name
        age
        setting
        learningProgression
        color
        # filterAttributes {
        #   developerType
        #   documentType
        #   focus
        #   ageRange
        #   language
        #   setting
        #   regionOfOrigin
        #   countryOfOrigin
        #   regionOfUse
        #   countryOfUse
        #   countryOfOriginIncomeLevel
        #   countryOfUseIncomeLevel
        # }
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkID
        frameworkName
        parentID
        tier
        definition
      }
    }
  }
`;
